@import url(https://fonts.googleapis.com/css2?family=Oswald&family=Raleway&family=Staatliches&display=swap);

/*
font-family: 'Oswald', sans-serif;
font-family: 'Raleway', sans-serif;
font-family: 'Staatliches', cursive;
*/
html {
  margin: 0;
  padding: 0;
}
.hero {
  display: grid;
  grid-template-columns: 4rem 4rem 1fr 1fr 4rem 4rem;
  grid-template-rows: 4em 1fr 1fr 4em;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  width: 100vw;
  height: 100%;
}
.hero-right-side {
  grid-area: 2 / 4 / 4 / 6;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.hero h1,
.hero h2 {
  padding: 0;
  font-family: 'Staatliches', cursive;
  text-align: center;
}
.hero h1 {
  font-size: 4rem;
  color: #4591bb;
  margin: 0;
}
.hero h2 {
  padding: 1rem;
  background-color: #6dd1d1;
  color: white;
  margin: 0;
}
.hero p {
  font-family: 'Raleway', sans-serif;
  color: #4591bb;
  font-size: 1.25rem;
  text-align: center;
}

.top-rectangle {
  grid-area: 1 / 1 / 2 / 3;
  background-color: #feda65;
}
.bottom-rectangle {
  grid-area: 4 / 5 / 5 / 7;
  background-color: #feda65;
}
.top-right-rectangle {
  grid-area: 1 / 5 / 2 / 7;
  background-color: #feda65;
}
.bottom-left-rectangle {
  grid-area: 4 / 1 / 5 / 3;
  background-color: #feda65;
}
.hero-left-side {
  grid-area: 2 / 2 / 4 / 4;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.hero-left-side img {
  max-height: 30em;
  max-width: 30em;
}
/* -------------- About ------------------- */
.about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  width: 100%;
  height: 100%;
}
.about-left-side {
  grid-area: 1 / 1 / 2 / 2;
  margin: 2rem;
}
.about-right-side {
  grid-area: 1 / 2 / 2 / 3;
  margin: 2rem;
}
.about-group h3 {
  font-family: 'Oswald', sans-serif;
  font-size: 2rem;
  margin: 0;
  color: #6dd1d1;
}
.about-group p {
  font-family: 'Raleway', sans-serif;
  color: #4591bb;
}
/* ------------- Project ----------------  */
.projects {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Oswald', sans-serif;
}
.project-title {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0;
}
.project-list {
  max-width: 1000px;
}
.projects h2 {
  font-family: 'Staatliches', cursive;
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
  color: #4591bb;
}
.project-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  width: 100%;
  height: 100%;
  margin: 1rem;
}
.project-card p {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  color: #4591bb;
}
.project-card h4 {
  font-size: 2rem;
  color: #6dd1d1;
  margin: 0;
  padding: 0;
}
.project-img-left {
  grid-area: 1 / 1 / 2 / 2;
  margin: 1rem;
}
.project-img-right {
  grid-area: 1 / 3 / 2 / 4;
  margin: 1rem;
}
.project-image img {
  width: 25rem;
  border: 2px solid #6dd1d1;
}
.project-desc-right {
  grid-area: 1 / 2 / 2 / 4;
  margin: 1rem;
}
.project-desc-left {
  grid-area: 1 / 1 / 2 / 3;
  margin: 1rem;
}
.project-card .links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}
.project-card a {
  text-decoration: none;
}
.languages {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 0.5rem;
}
.language-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}
.yellow-circle {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background-color: #feda65;
  margin: 0.25rem;
}
.teal-circle {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background-color: #6dd1d1;
  margin: 0.25rem;
}
/* .project-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.project-card {
  width: 25rem;
  height: 25rem;
  background-color: #6dd1d1;
  margin: 1rem;
  border: 5px solid #6dd1d1;
}
.project-card-details {
  display: flex;
  flex-direction: column;
}
.languages {
  display: flex;
  flex-direction: row;
}
.project-card img {
  width: 25rem;
}
.project-card-details {
  font-family: 'Raleway', sans-serif;
} */

/*  Connect   */
.connect {
  display: grid;
  grid-template-columns: 4rem 4rem 1fr 1fr 4rem 4rem;
  grid-template-rows: 4em 1fr 1fr 4em;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  width: 100vw;
  height: 100%;
}
.connect h2 {
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
  color: #4591bb;
  font-family: 'Oswald', sans-serif;
}
.connect-content {
  grid-area: 2 / 3 / 4 / 5;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.logo {
  height: 2rem;
  margin: 0.5rem;
}
.connect button {
  background-color: #6dd1d1;
  border: 1px solid #feda65;
  padding: 1rem;
  border-radius: 1rem;
  font-family: 'Staatliches', cursive;
  font-size: 1.5rem;
  color: white;
  margin: 1rem;
}
.connect-links {
  font-size: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.logo-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 1rem;
}
.logo-group a {
  font-family: 'Raleway', sans-serif;
}

.resume-top {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  color: #4591bb;
  font-family: 'Oswald', sans-serif;
}
.resume-top h2 {
  margin: 0;
  margin-left: 1rem;
  padding: 0;
}
.resume-top button {
  background-color: #6dd1d1;
  border: 1px solid #feda65;
  border-radius: 1rem;
  font-family: 'Staatliches', cursive;
  font-size: 1.5rem;
  color: white;
  height: 3rem;
}
@media screen and (max-width: 1050px) {
  .hero {
    grid-template-rows: 4em 1fr 0.5fr 4em;
  }
  .hero-left-side {
    grid-area: 2 / 2 / 3 / 6;
  }
  .hero-right-side {
    grid-area: 3 / 2 / 4 / 6;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
  }
}
@media screen and (max-width: 700px) {
  html {
    font-size: 12px;
  }
}

